import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Connected Supply Chains"
        paragraph="Navigating the Future of Logistics in a Digital World"
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_1/A2.webp" alt="technology illustration of interconnected nodes touching a point" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <h1 className="d-none">
                          Connected Supply Chains
                          </h1>
                          <h2>
                            - The Power of Connectivity  
                          </h2>
                          <p>
                            Connected supply chains leverage digital technologies to create a seamless flow of information and resources across the entire supply chain ecosystem. From suppliers and manufacturers to distributors and customers, every stakeholder is connected through a network of integrated systems and real-time data exchanges. This level of connectivity enables companies to optimize their operations, improve visibility, and respond quickly to changing market conditions, ultimately driving efficiency, and enhancing customer satisfaction.
                          </p>
                          <h2>- Exploring the Possibilities </h2>
                          <p>
                            With connected supply chain services, the possibilities are endless. Imagine a world where inventory levels are automatically adjusted based on demand forecasts, production schedules are optimized in real-time, and shipments are tracked from source to destination with pinpoint accuracy. This level of automation and visibility not only reduces costs and minimizes risks but also enables companies to deliver products faster and more efficiently than ever before.
                          </p>
                          <h2>- The Role of Elfonze Technologies  </h2>
                          <p>
                            As a leading provider of technology solutions and consulting services, Elfonze Technologies is dedicated to helping businesses navigate the complexities of modern logistics and unlock the full potential of connected supply chains. Through strategic partnerships and innovative solutions, Elfonze empowers companies to streamline their operations, optimize their resources, and stay ahead of the competition in today's digital world.
                          </p>
                          <h2>- Benefits of Connected Supply Chains  </h2>
                          <p>
                            The benefits of connected supply chains are numerous and far-reaching. By embracing digital transformation and adopting connected supply chain services, companies can:
                          </p>
                          <ul className="smp-list ab--left-align custom_sublist">
                            <li>
                              <p className="no-margin">Streamline operations and eliminate bottlenecks to improve overall efficiency and productivity.</p>
                            </li>
                            <li>
                              <p className="no-margin">Gain real-time visibility into inventory levels, production schedules, and shipment statuses to make better-informed decisions.</p>
                            </li>
                            <li>
                              <p className="no-margin">Respond quickly to changing market conditions and customer demands with agile, data-driven supply chain strategies.</p>
                            </li>
                            <li>
                              <p className="no-margin">Minimize inventory carrying costs, transportation expenses, and order fulfilment times through optimized supply chain processes.</p>
                            </li>
                            <li>
                              <p className="no-margin">Deliver products faster and more accurately, leading to increased customer satisfaction and loyalty.</p>
                            </li>
                          </ul>
                          <br/>
                          <div className="share-info"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Connected Supply Chains: Benifits|Possibilities|Connectivity</title>
      <meta key="description" name="description" 
      title="Connected Supply Chains: Revolutionizing Global Logistics"
        content="Discover how Elfonze Technologies helps streamline operations, boost visibility, and optimize resources. Explore Connected Supply Chains—start innovating today!" />
    </>
  )
}

export default BlogDetailsDark;
